import React from 'react';

export default function IconRoadbook({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1109 -452)" fill="none">
        <rect transform="translate(1109 452)" width="24" height="24" />
        <g transform="translate(1113.4 452)">
          <rect
            transform="translate(.649 4.473)"
            width="15.508"
            height="18.878"
            stroke="#1a1818"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            transform="translate(.317 .049)"
            d="M15.84,4.423,5.323.665A1.1,1.1,0,0,0,3.856,1.7v2.17"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <line
            transform="translate(5.886 19.941)"
            x2="5.125"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            transform="translate(.632 .752)"
            d="M7.682,15.68V12.468L11,9.153"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeWidth="1.2"
          />
          <line
            transform="translate(4.678 9.694)"
            x2="3.449"
            y2="3.449"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeWidth="1.2"
          />
          <path
            transform="translate(.772 .712)"
            d="m9.393 8.657h1.984v2.05z"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <rect width="16.807" height="24" />
        </g>
      </g>
    </svg>
  );
}
