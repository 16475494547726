import React from 'react';
import { inject, observer } from 'mobx-react';

import InboxButton from '../InboxButton';
import AvatarDropdownMenu from '../AvatarDropdownMenu';

import styles from './UserControls.module.scss';

import routes from '@constants/routes';
import Button from '@components/shared/Button/Button';
import { userMenuItems } from '@constants/common';

const UserControls = ({
  userStore,
  buttonssize,
  commercialPage,
  chatId
}: {
  userStore?: any;
  buttonssize?: string;
  commercialPage?: boolean;
  chatId?: number;
}) => (
  <div className={styles['header-user']}>
    {commercialPage && (
      <div className="user-controls__request-button">
        <Button variant="primary" className="header__request-button" size={buttonssize} href={routes.requestForm.index}>
          Craft your trip
        </Button>
      </div>
    )}
    <InboxButton notificationCount={userStore.notificationsCount} chatId={chatId} colorful />
    <AvatarDropdownMenu userStore={userStore} items={userMenuItems} colorful />
  </div>
);

export default inject('userStore')(observer(UserControls));
