import React from 'react';

export default function IconSliderHandle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      style={{ boxShadow: '0px 2px 6px #6C5AC64D', borderRadius: '50%' }}
    >
      <defs>
        <linearGradient id="gradient" x1="0.786" y1="0.131" x2="-0.246" y2="1.187" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#a200ed" />
          <stop offset="1" stopColor="#006cff" />
        </linearGradient>
      </defs>
      <g>
        <circle style={{ fill: 'url(#gradient)' }} cx="14" cy="14" r="14" />
        <circle style={{ fill: '#fff' }} cx="12" cy="12" r="12" transform="translate(2 2)" />
        <g transform="translate(12 11)">
          <path
            d="M0,0l-3,3,3,3"
            transform="translate(0 0)"
            fill="none"
            stroke="#c0bacc"
            strokeLinecap="round"
            strokeWidth="1.2"
          />
          <path
            d="M0,0l3,3-3,3 "
            transform="translate(4 0)"
            fill="none"
            stroke="#c0bacc"
            strokeLinecap="round"
            strokeWidth="1.2"
          />
        </g>
      </g>
    </svg>
  );
}
