import React from 'react';

export default function IconSupport({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath>
          <rect width="20.817" height="20.327" fill="none" />
        </clipPath>
      </defs>
      <g transform="translate(-1109 -452)">
        <rect transform="translate(1109 452)" width="24" height="24" fill="none" />
        <g transform="translate(1110.6 453.84)">
          <g transform="translate(0)" clipPath="url(#a)" fill="none" stroke="#1a1818" strokeWidth="1.2">
            <path
              transform="translate(.205 .264)"
              d="M6.379,15.144a6.956,6.956,0,0,0,6.632,4.3,7.539,7.539,0,0,0,3.673-.943l1.839.764a.742.742,0,0,0,.992-.907l-.586-1.862a5.761,5.761,0,0,0,1.064-3.31,6.051,6.051,0,0,0-2.721-4.959"
            />
            <path
              transform="translate(.019 .019)"
              d="M8.944.6C4.336.6.6,3.949.6,8.08a6.9,6.9,0,0,0,1.337,4.052l-.686,2a.669.669,0,0,0,.874.841L4.148,14.2a8.968,8.968,0,0,0,4.8,1.365c4.608,0,8.344-3.35,8.344-7.481S13.553.6,8.944.6Z"
            />
            <circle transform="translate(3.579 7.129)" cx="1.042" cy="1.042" r="1.042" />
            <circle transform="translate(7.921 7.269)" cx="1.042" cy="1.042" r="1.042" />
            <circle transform="translate(12.178 7.129)" cx="1.042" cy="1.042" r="1.042" />
          </g>
        </g>
      </g>
    </svg>
  );
}
