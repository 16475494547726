import React from 'react';

export default function IconConcierge({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath>
          <rect transform="translate(0)" width="19.354" height="15.962" fill="none" />
        </clipPath>
      </defs>
      <g transform="translate(-1109 -452)">
        <rect transform="translate(1109 452)" width="24" height="24" fill="none" />
        <g transform="translate(1111.3 456.02)">
          <g
            clipPath="url(#a)"
            fill="none"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          >
            <path d="M17.309,12.751H2.062V12.23a7.1,7.1,0,0,1,7.1-7.1h1.042a7.1,7.1,0,0,1,7.1,7.1Z" />
            <path d="M17.448,15.362H1.9a1.306,1.306,0,0,1,0-2.611H17.448a1.306,1.306,0,0,1,0,2.611Z" />
            <line transform="translate(9.677 2.771)" y1="2.357" />
            <path d="m11.632 2.771h-3.91a1.086 1.086 0 1 1 0-2.171h3.91a1.086 1.086 0 0 1 0 2.171z" />
          </g>
        </g>
      </g>
    </svg>
  );
}
