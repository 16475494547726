import React from 'react';

export default function IconTripCrafting({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1109 -452)" fill="none">
        <rect transform="translate(1109 452)" width="24" height="24" />
        <g
          transform="translate(1111.1 455.22)"
          stroke="#1a1818"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.2"
        >
          <path transform="translate(.062 .082)" d="M6.718,13.492.6,15.15V2.449L6.718.791Z" />
          <path transform="translate(.634 .082)" d="m12.264 16.813-6.118-3.321v-12.7l6.118 3.32z" />
          <path transform="translate(1.205 .316)" d="m16.2 15.353-4.5 1.226v-12.7l2.984-0.812" />
          <path transform="translate(1.491 .062)" d="M17.685,12.829l-1.609,2.432-1.61-2.432V.6h3.22Z" />
          <line transform="translate(15.956 3.383)" x1="3.22" />
        </g>
      </g>
    </svg>
  );
}
