import isArray from 'lodash/isArray';

import { adjustmentPerSoloTraveler } from '@constants/constants';

export function getTripPreview(trip) {
  if (trip.image) {
    return trip.image;
  }

  return getMainPhoto(trip.destination?.cover_photos);
}

export function getMainPhoto(photos) {
  if (!isArray(photos)) {
    return;
  }
  return photos.find((image) => image.is_main);
}

export function getMapCenter(destination) {
  const longtitude = destination?.longitude || 0;
  const latitude = destination?.latitude || 0;
  return [longtitude, latitude];
}

export function getTripBuilderCoverPhoto(trip) {
  return trip.tripbuilder_itinerary?.media?.[0]?.media?.cover;
}

export function getNewCost(cost, travelers, days) {
  return cost * travelers * days * (travelers === 1 ? adjustmentPerSoloTraveler : 1);
}
