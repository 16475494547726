import React from 'react';

export default function IconAccommodation({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1109 -452)">
        <rect transform="translate(1109 452)" width="24" height="24" fill="none" />
        <g transform="translate(1111.5 454.64)">
          <g transform="translate(.275 .306)" fill="none" stroke="#1a1818">
            <path
              transform="translate(-.45 -.5)"
              d="M18.833,18.612,10.222.864a.646.646,0,0,0-1.162,0L.45,18.612"
              strokeWidth="1.2"
            />
            <line transform="translate(.631 17)" x2="17.141" strokeWidth="1.2" />
            <line transform="translate(15.79 1.275)" y1="11.507" strokeWidth="1.2" />
            <path
              transform="translate(-14.19 -23.573)"
              d="M26.046,40.573H20.759V35.357a.753.753,0,0,1,.753-.753h3.782a.753.753,0,0,1,.753.753Z"
              strokeWidth="1.2"
            />
            <line transform="translate(5.367 7.092)" x2="7.653" strokeWidth="1.2" />
          </g>
        </g>
      </g>
    </svg>
  );
}
