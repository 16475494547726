import React from 'react';

export default function IconEntryExit({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1109 -452)">
        <rect transform="translate(1109 452)" width="24" height="24" fill="none" />
        <g transform="translate(1112.6 452)">
          <rect
            transform="translate(.649 4.473)"
            width="15.508"
            height="18.878"
            fill="none"
            stroke="#1a1818"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <path
            transform="translate(.317 .049)"
            d="M15.84,4.423,5.323.665A1.1,1.1,0,0,0,3.856,1.7v2.17"
            fill="none"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <line
            transform="translate(5.886 19.941)"
            x2="5.125"
            fill="none"
            stroke="#1a1818"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.2"
          />
          <rect width="16.807" height="24" fill="none" />
          <g transform="translate(3.403 7)" fill="#fff" stroke="#1a1818">
            <circle cx="5" cy="5" r="5" stroke="none" />
            <circle cx="5" cy="5" r="4.5" fill="none" />
          </g>
          <path
            transform="translate(4.571 5.862)"
            d="M7.528,3.566c-.147.594-1.746,1.291-3.67,1.291A5.31,5.31,0,0,1,.25,3.648"
            fill="none"
            stroke="#1a1818"
          />
          <path
            transform="translate(4.572 9.7)"
            d="M7.528,4.857c-.147-.594-1.746-1.291-3.671-1.291A5.31,5.31,0,0,0,.25,4.774"
            fill="none"
            stroke="#1a1818"
          />
          <path
            transform="translate(11.011 7.516) rotate(90)"
            d="M8.969,2.886C8.788,1.559,6.817,0,4.445,0A5.109,5.109,0,0,0,0,2.7"
            fill="none"
            stroke="#1a1818"
          />
          <path
            transform="translate(8.772 7.516) rotate(90)"
            d="M8.969,0C8.788,1.327,6.817,2.886,4.445,2.886A5.109,5.109,0,0,1,0,.184"
            fill="none"
            stroke="#1a1818"
          />
          <line transform="translate(8.452 7.5)" y2="9" fill="none" stroke="#1a1818" />
        </g>
      </g>
    </svg>
  );
}
