import React, { useRef } from 'react';
import {
  SliderUnstyled,
  sliderUnstyledClasses,
  SliderUnstyledProps,
  SliderUnstyledThumbSlotProps,
  SliderUnstyledValueLabelSlotProps
} from '@mui/base';
import { Box, CustomTheme, Stack, styled, Typography, useMediaQuery } from '@mui/material';

import IconSliderHandle from '@components/shared/Icons/IconSliderHandle';
import { defaultCurrencyCode } from '@constants/constants';
import { elsewhere } from '@styles/theme';

interface SliderStyledProps extends SliderUnstyledProps {
  variant: 'raised' | 'flat';
}

const StyledSlider = styled(SliderUnstyled, { shouldForwardProp: (prop) => prop !== 'variant' })<SliderStyledProps>(
  ({ theme, variant }: { theme: CustomTheme; variant: 'raised' | 'flat' }) => ({
    width: '100%',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    touchAction: 'none',
    opacity: 1,

    '.Mui-disabled': {
      pointerEvents: 'none',
      cursor: 'default',
      color: '#bdbdbd'
    },

    '.MuiSlider-rail': {
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '6px',
      borderRadius: '8px',
      backgroundColor: variant === 'flat' ? theme.elsewhere.color['gray-10'] : theme.elsewhere.color['gray-3']
    },

    '.MuiSlider-thumb': {
      position: 'absolute',
      width: '28px',
      height: '28px',
      marginLeft: '-14px',
      marginTop: '-10px',
      borderRadius: '50%',

      ':hover,&.Mui-focusVisible': {
        boxShadow: theme.elsewhere.shadow['slider_thumb_hover']
      },

      '&.Mui-active': {
        boxShadow: theme.elsewhere.shadow['slider_thumb_hover']
      }
    },

    '.MuiSlider-mark': {
      position: 'absolute',
      width: '2px',
      height: '6px',
      backgroundColor: variant === 'flat' ? theme.elsewhere.color['gray-10'] : theme.elsewhere.color['gray-3'],
      top: '-6px',
      transform: 'translateX(-50%)'
    },

    '.MuiSlider-markLabel': {
      color: theme.elsewhere.color['gray-5'],
      fontSize: '12px',
      position: 'absolute',
      top: '-28px',
      transform: 'translateX(-2px)'
    }
  })
);

function Thumb({ children, ownerState, ...other }: SliderUnstyledThumbSlotProps) {
  const ref = useRef<HTMLSpanElement>(null);

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === ValueLabel) {
      return React.cloneElement(child, { ...child.props, thumbRef: ref });
    }
    return child;
  });

  return (
    <Box ref={ref} component="span" {...other}>
      {childrenWithProps}
      <IconSliderHandle />
    </Box>
  );
}

interface ValueLabelProps extends SliderUnstyledValueLabelSlotProps {
  value: number;
  valueLabelFormatter: (value: number) => string | number;
  additionalValueLabel?: React.ReactNode;
  thumbRef: React.RefObject<HTMLSpanElement>;
}

function ValueLabel({
  ownerState: _ownerState,
  valueLabelFormat: _valueLabelFormat,
  value,
  valueLabelFormatter,
  additionalValueLabel,
  thumbRef,
  ...other
}: ValueLabelProps) {
  const labelRef = useRef<HTMLDivElement>(null);

  const { left: labelLeft, width: labelWidth } = labelRef?.current?.getBoundingClientRect() || {
    left: 0,
    width: 0
  };

  const { left: thumbLeft, width: thumbWidth } = thumbRef?.current?.getBoundingClientRect() || {
    left: 0,
    width: 0
  };

  const { left: railLeft, right: railRight } = document.querySelector('.MuiSlider-rail')?.getBoundingClientRect() || {
    left: 0,
    right: 0
  };

  const halfLabelWidth = labelWidth / 2;
  const halfThumbWidth = thumbWidth / 2;
  const centerThumb = thumbLeft + halfThumbWidth;
  const defaultOffset = -halfLabelWidth + halfThumbWidth;

  const offset = () => {
    if (centerThumb <= railLeft + halfLabelWidth - halfThumbWidth) {
      return railLeft - centerThumb;
    }
    if (centerThumb >= railRight - halfLabelWidth + halfThumbWidth) {
      return railRight - centerThumb - labelWidth + thumbWidth;
    }
    return defaultOffset;
  };

  const triangleOffset = thumbLeft <= labelLeft ? labelLeft - thumbLeft : 0;

  return (
    <Box {...other} sx={{ position: 'absolute', top: '-112px' }}>
      <Stack
        ref={labelRef}
        alignItems="center"
        sx={{
          width: '160px',
          height: '94px',
          backgroundColor: elsewhere.color['white'],
          transform: `translateX(${offset()}px)`,
          padding: '8px',
          border: 'solid rgba(108, 90, 198, 0.15) 1px',
          borderRadius: elsewhere.borderRadius[12],
          boxShadow: '0px 2px 6px rgba(108, 90, 198, 0.15)'
        }}
      >
        <Typography
          sx={{
            color: elsewhere.color['gray-8'],
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            fontWeight: elsewhere.fontWeight.medium
          }}
        >
          {valueLabelFormatter(value)}
        </Typography>
        {additionalValueLabel}
      </Stack>
      <Box
        component="span"
        sx={{
          position: 'absolute',
          bottom: '-7px',
          left: '7px',
          transform: `translateX(${triangleOffset}px)`,
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '8px 7px 0 7px',
          borderColor: `rgba(108, 90, 198, 0.15) transparent transparent transparent`,
          '&:after': {
            content: '""',
            position: 'absolute',
            top: '-8px',
            left: '-6px',
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '7px 6px 0 6px',
            borderColor: `${elsewhere.color.white} transparent transparent transparent`
          }
        }}
      />
    </Box>
  );
}

export default function Slider({
  value,
  defaultValue,
  min,
  max,
  step,
  onChange,
  variant = 'raised',
  marks,
  valueLabelFormatter = (value) => value,
  additionalValueLabel,
  faqSlot,
  showCurrencyCode
}: {
  value: number;
  defaultValue: number;
  min: number;
  max: number;
  step: number;
  onChange: (value: number) => void;

  variant?: 'raised' | 'flat';
  marks?: { value: number; label: string }[];
  valueLabelFormatter?: (value: number) => string | number;
  additionalValueLabel?: React.ReactNode;
  faqSlot?: React.ReactNode;
  showCurrencyCode?: boolean;
}) {
  // Hide first and last marks
  const markElements = document?.querySelectorAll<HTMLHtmlElement>(`.${sliderUnstyledClasses.mark}`);
  const firstMark = markElements?.[0];
  const lastMark = markElements?.[markElements.length - 1];
  if (firstMark) {
    firstMark.style.display = 'none';
  }
  if (lastMark) {
    lastMark.style.display = 'none';
  }

  const sm = useMediaQuery((theme: CustomTheme) => theme.breakpoints.up('sm'));

  const variantRaised = {
    border: { xs: `solid rgba(138, 133, 151, 0.24) 1px`, sm: 'none' },
    borderRadius: { xs: elsewhere.borderRadius[12], sm: '0px' },
    boxShadow: { xs: `0px 2px 6px rgba(138, 133, 151, 0.24)`, sm: 'none' }
  };

  const varaintFlat = {
    background: {
      xs: `transparent linear-gradient(39deg, ${elsewhere.color['blue-super-light']} 0%, ${elsewhere.color['gray-11']} 100%) 0% 0% no-repeat padding-box;`,
      sm: `transparent linear-gradient(52deg, ${elsewhere.color['blue-super-light']} 0%, ${elsewhere.color['gray-11']} 100%) 0% 0% no-repeat padding-box`
    },
    borderRadius: { xs: elsewhere.borderRadius[16], sm: elsewhere.borderRadius[24] }
  };

  return (
    <Box
      sx={{
        ...{ raised: variantRaised, flat: varaintFlat }[variant],
        width: '100%',
        padding: { xs: '16px 18px 24px', sm: '8px 40px 22px' },
        px: { md: variant === 'flat' ? '40px' : '14px' },
        mx: { md: '-14px' },
        overflow: 'hidden'
      }}
    >
      {!sm && (
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="h5" sx={{ color: elsewhere.color['gray-8'], lineHeight: '1.75rem' }}>
              {valueLabelFormatter(value)}
            </Typography>
            {additionalValueLabel}
          </Box>
          <Box>{faqSlot}</Box>
        </Stack>
      )}
      <StyledSlider
        sx={{ mt: { xs: '40px', sm: '132px' } }}
        value={value}
        step={step}
        min={min}
        max={max}
        marks={marks}
        variant={variant}
        slots={{
          thumb: Thumb,
          valueLabel: sm ? ValueLabel : undefined
        }}
        slotProps={{
          valueLabel: sm ? { value, valueLabelFormatter, additionalValueLabel } : undefined
        }}
        onChange={(_event: Event, value: number) => onChange(value)}
      />
      {sm && (
        <Stack direction="row" justifyContent="space-between" sx={{ pt: '6px' }}>
          <Typography variant="body2">
            {showCurrencyCode && `${defaultCurrencyCode} `}
            {valueLabelFormatter(min)}
          </Typography>
          <Typography variant="body2">
            {' '}
            {showCurrencyCode && `${defaultCurrencyCode} `}
            {valueLabelFormatter(max)}
          </Typography>
        </Stack>
      )}
    </Box>
  );
}
