import React from 'react';

export default function IconNotIncluded({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1109 -452.49)" fill="none">
        <rect transform="translate(1109 452.49)" width="18" height="18" />
        <line
          transform="translate(1113 456.49)"
          x2="10"
          y2="10"
          stroke="#E03A86"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <line
          transform="translate(1113 456.49)"
          x2="10"
          y1="10"
          stroke="#E03A86"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
