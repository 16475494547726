import React from 'react';
import classnames from 'classnames';

import styles from './GuestControls.module.scss';

import Icon from '@components/shared/Icon/Icon';
import Button from '@components/shared/Button/Button';
import routes from '@constants/routes';
import { elementClicked } from '@utils/sendEvent';

const GuestControls = ({ className, buttonssize }) => (
  <div className={classnames(styles['header-guest'], styles[className])}>
    <Button
      variant="primary"
      className="header__request-button"
      size={buttonssize}
      href={routes.requestForm.index}
      onClick={() =>
        elementClicked({
          actionOutcome: 'INTERNALLINK',
          outboundUrl: routes.requestForm.index,
          webElement: {
            elementType: 'Link',
            location: 'Homepage',
            name: 'Sticky CTA',
            position: '0',
            text: 'Craft your trip'
          }
        })
      }
    >
      Craft your trip
    </Button>
    <Button className={styles['header-guest__login-button']} size={buttonssize} href={routes.auth.login.index}>
      <span className="login-text">Login</span>
      <Icon className="login-icon" icon="icon_24_user" size={24} />
    </Button>
  </div>
);

export default GuestControls;
