import React from 'react';

export default function IconTransportation({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1109 -452)" fill="none">
        <rect transform="translate(1109 452)" width="24" height="24" />
        <g transform="translate(-272.77 -66.277)" stroke="#1c1a21" strokeWidth="1.2">
          <path
            transform="translate(1383.8 521.77)"
            d="M3.23,14.381H2.61A2.11,2.11,0,0,1,.5,12.272V2.755A2.255,2.255,0,0,1,2.755.5h8.486V14.381H7.5"
          />
          <path transform="translate(1395 536.15)" d="M4.71,0H0" />
          <path
            transform="translate(1356 521.77)"
            d="M38.969.5H40.01a3.475,3.475,0,0,1,2.785,1.4l3.384,4.537a3.836,3.836,0,0,1,.331,4.06v1.438"
          />
          <path transform="translate(1383.6 503.85)" d="M.792,25.357H11.533" />
          <line transform="translate(1395.3 530.97)" x2="7.712" strokeLinecap="round" />
          <circle transform="translate(1387 534.01)" cx="2.141" cy="2.141" r="2.141" />
          <circle transform="translate(1400.1 533.99)" cx="2.141" cy="2.141" r="2.141" />
        </g>
      </g>
    </svg>
  );
}
