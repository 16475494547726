import React from 'react';

export default function IconExperiences({ width = 24, height = 24 }) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(-1109 -452)" fill="none">
        <rect transform="translate(1109 452)" width="24" height="24" />
        <g transform="translate(1110.5 453.54)" stroke="#1c1a21" strokeWidth="1.2">
          <path
            transform="translate(-11.983 -.217)"
            d="m18.119 10.646a15.544 15.544 0 0 0 3.428 9.754 1.188 1.188 0 0 0 1.854 0 15.6 15.6 0 0 0 0.079-19.414 1.284 1.284 0 0 0-2.012 0 15.543 15.543 0 0 0-3.349 9.66z"
          />
          <path
            transform="translate(-17.501 -12.758)"
            d="m27.992 27.109a1.611 1.611 0 0 1-1.611-1.609v-4.61a1.611 1.611 0 1 1 3.221 0v4.61a1.611 1.611 0 0 1-1.61 1.609z"
          />
          <path
            transform="translate(-.216 -17.953)"
            d="M2.972,29.531H.935A.435.435,0,0,1,.5,29.1v-1.6a.435.435,0,0,1,.435-.435H2.972l1.55,1.327Z"
          />
          <line transform="translate(4.306 10.423)" x1="12.453" />
          <path
            transform="translate(-33.175 -18.286)"
            d="M51.4,27.557h2.037a.435.435,0,0,1,.435.435v1.6a.435.435,0,0,1-.435.435H51.4L49.851,28.7Z"
          />
        </g>
      </g>
    </svg>
  );
}
